import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  Tab,
  Typography,
} from '@mui/material';
import Bupload from 'assets/images/bupload.png';
import Success from 'assets/images/success.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomModalView from 'components/CustomModalView/customModalView';
import { FileBulkUploadDialog } from 'components/Fileupload/fileBulkUploadDialog';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
// import Pagination from 'components/Pagination';
import { PassportParsing } from 'components/ParsingDocumentView/passport';
import { PdfParsing } from 'components/ParsingDocumentView/pdfParsing';
import { ResumeParsing } from 'components/ParsingDocumentView/resume';
import { AttorneyPrivateRoutes } from 'config';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import { DocumentBulkDownload } from 'pages/private/Common/bulkDownload';
import SectionDocumentsList from 'pages/private/Common/SectionDocumentsList';
import { SectionList } from 'pages/private/Common/SectionList';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { PassportTypes, UserRoleType } from 'types';
import { BASE_URL, COMMON_ENDPOINT, SETUP_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  changeDobFormate,
  fileTypeCheckSkillParsing,
  formateDate,
  getGenderValues,
  parsingTypeArr,
  passportDefaultValues,
  resumeDefaultValues,
} from 'utils/helper';
import Storage from 'utils/Storage';

import UploadDocumentList from '../../Common/LeadsOrPetitions/Lists/UploadDocumentList';
import Depentent from '../CompleteQuestion/Depentent';
import UploadPetitionsDocumentTableDisplay from './UploadPetitionsDocumentTableDisplay';
import UploadPetitionsDocumentTableHeadting from './UploadPetitionsDocumentTableHeadting';

export const UploadPetitionsDocument: React.FC<any> = () => {
  const { editId, petitionId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions } = useAppSelector((store: any) => store.petitionUser);
  const location: any = useLocation();
  const [loader, setLoader] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    doc: {},
    index: null,
    show: false,
  });
  const [copyOrganizatioDocument, setCopyOrganizationDocument] = useState({
    data: [],
    open: false,
  });
  const [tabValue, setTabValue] = useState(
    location.state && location.state?.tab ? location.state?.tab : '1',
  );
  const [sectionSearch, setSectionSearch] = useState('');
  const [visaSearch, setVisaSearch] = useState('');
  const [copyDocumentSection, setCopyDocumentSection] = useState<{
    open: boolean;
  }>({
    open: false,
  });
  const [requiredDocumentSection, setRequiredDocumentSection] = useState<{
    open: boolean;
    scheduleData: any;
  }>({
    open: false,
    scheduleData: [],
  });
  const [params] = useState<any>({
    benificieryId: '',
    firmId: userDetail?.role_id == 3 ? userDetail?.companyId : '',
    id: petitionId ? petitionId : editId,
    leadId: '',
    name: '',
    organizationId: '',
    skip: 0,
    take: 50,
  });
  const [uploadDocumentdailog, setUploadDocumentdailog] = useState<any>(false);
  const [copyDocparams, setCopyDocParams] = useState<any>({
    associated: '',
    documentType: '',
    firmId: '',
    visaId: '',
  });
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const debouncedParams = useDebounce(copyDocparams, 500);
  const [petitionerId, setPetitionerId] = useState();
  const [petitonerStatus, setPetitonerStatus] = useState();
  const [beneficiaryDocuments, setBeneficiaryDocuments] = useState<any>([]);
  const [petitioneryDocuments, setPetitioneryDocuments] = useState<any>([]);
  const [requestDocuments, setRequestDocuments] = useState<any>({});
  const [fileUploadOpen, setFileUploadOpen] = useState<{
    open: boolean;
    documentInfo: any;
    roleId: number | any;
    editDocId: any;
  }>({
    documentInfo: null,
    editDocId: null,
    open: false,
    roleId: null,
  });
  const [isUploadDocs, setIsUploadDocs] = useState({
    index: 0,
    show: true,
  });
  const selectSections = (sectionsInfo: any) => {
    return false;
  };
  const parsedValuesView = (
    parsingTypeCheck,
    view,
    documentId,
    roleId,
    checking?,
  ) => {
    if (view && view.is_parsing_value_submitted) {
      toast.info(
        'Parsing information already reviewed and submitted.',
        alertOptions,
      );
      return;
    }
    if (parsingTypeCheck === 'i-129') {
      if (
        view &&
        view.from_bulk_document_parsing &&
        view.document_data &&
        Object.keys(view.document_data).length
      ) {
        navigate(
          `${AttorneyPrivateRoutes.DOCUMENTI129PREVIEW}/${String(
            view.documentId,
          )}`,
        );
        return;
      } else if (
        view &&
        view.from_bulk_document_parsing &&
        (!view.document_data || !Object.keys(view.document_data).length)
      ) {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      } else if (
        view &&
        !view.from_bulk_document_parsing &&
        view.form_extraction_id &&
        view.document_data &&
        Object.keys(view.document_data).length
      ) {
        navigate(
          `${AttorneyPrivateRoutes.DOCUMENTI129PREVIEW}/${String(
            view.documentId,
          )}`,
        );
        return;
      } else if (
        view &&
        !view.from_bulk_document_parsing &&
        view.form_extraction_id &&
        (!view.document_data || !Object.keys(view.document_data).length)
      ) {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      } else {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      }
    }
    if (
      view &&
      (!view.document_data ||
        (Array.isArray(view.document_data) && !view.document_data.length))
    ) {
      toast.info(
        'Parsing data yet to be updated. Please check back later.',
        alertOptions,
      );
      return;
    }
    navigate(
      `${AttorneyPrivateRoutes.DOCUMENTPREVIEW}/${String(view.documentId)}`,
    );
    return;
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadType, setUploadType] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recallTime, setRecallTime] = useState(0);
  const [passportValues, setPassportValues] = useState<PassportTypes>(
    passportDefaultValues,
  );
  const [resumeInfo, setResumeInfo] = useState<{
    474: string[];
    284: string;
  }>(resumeDefaultValues);
  const [parsingData, setParsingData] = useState<Array<any>>([]);
  const [leadInfo, setLeadInfo] = useState(null);
  const [preview, setPreview] = useState(false);
  const [tempI_129, setTempI_129] = useState(false);
  const [fileSrc, setFileSrc] = useState('');
  const [copyFile, setCopyFile] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countDetails, setCountDetails] = useState<any>({
    pending: 0,
    total: 0,
  });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadCheck, setUploadCheck] = useState(true);
  // const [isDocumentReview, setIsDocumentReview] = useState<any>({
  //   reviewDoc: false,
  //   token: null,
  // });

  const closeDocumentSection = () => {
    setRequiredDocumentSection((pre) => ({
      ...pre,
      open: false,
    }));
    getList();
  };

  const openCopyDocument = () => {
    setUploadDocumentdailog(false);
    setCopyDocumentSection((pre) => ({
      ...pre,
      open: true,
      scheduleData: [],
    }));
    setSectionSearch('');
    setVisaSearch('');
  };
  const uploadSelectedDoc = () => {
    const selectedDocuments: any = [];
    copyOrganizatioDocument?.data?.map((e) => {
      e.multipleDocumentsList?.map((data) => {
        data['checked']
          ? selectedDocuments.push({
              currentDocumentId: e.currentDocumentId,
              documentPath: data.document_path,
              mimeType: data.mime_type,
              originalName: data.original_name,
            })
          : '';
      });
    });
    const payload = {
      organizationId: petitionId,
      roleId: 2,
      selectedDocuments: selectedDocuments,
    };

    updateData(
      '',
      payload,
      `${String(SETUP_ENDPOINT.CopyPetitionerData)}/${petitionId}`,
    )
      .then((resp: any) => {
        getList();
        setCopyOrganizationDocument((pre) => ({
          ...pre,
          open: false,
        }));
        toast.success('Upload successfully', alertOptions);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const openOrganizationDocument = () => {
    setUploadDocumentdailog(false);
    setCopyOrganizationDocument((pre) => ({
      ...pre,
      open: true,
    }));
    setCopyDocParams((pre) => ({
      ...pre,
      documentType: '',
      visaId: '',
    }));
    // copyDocumentList();
  };
  const removeOrAddInMultiDoc = (list, mainIndex, subIndex, checked) => {
    list[mainIndex].multipleDocumentsList[subIndex].checked = checked;
    setCopyOrganizationDocument((pre) => ({
      ...pre,
      data: [...list],
    }));
  };
  const selectAllDocument = (ele) => {
    setCopyOrganizationDocument((pre) => ({
      ...pre,
      data: [
        ...pre.data.map((e) => {
          return {
            ...e,
            multipleDocumentsList: [
              ...e.multipleDocumentsList.map((multi) => ({
                ...multi,
                checked: ele?.target.checked,
              })),
            ],
          };
        }),
      ],
    }));
  };
  const checkAll = () => {
    if (copyOrganizatioDocument?.data?.length > 0) {
      return copyOrganizatioDocument?.data.filter((val) =>
        val.multipleDocumentsList.every((el) => el.checked === true),
      ).length === copyOrganizatioDocument?.data.length
        ? true
        : false;
    } else {
      return false;
    }
  };
  const removeOrAddDoc = (currentData, checked) => {
    setCopyOrganizationDocument((pre) => ({
      ...pre,
      data: [
        ...pre.data.map((e) => {
          return {
            ...(e.currentDocumentId === currentData.currentDocumentId
              ? {
                  ...e,
                  multipleDocumentsList: [
                    ...e.multipleDocumentsList.map((multi) => ({
                      ...multi,
                      checked: checked,
                    })),
                  ],
                }
              : e),
          };
        }),
      ],
    }));
  };
  useEffect(() => {
    if (petitionerId) {
      copyDocumentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams]);
  const getPetitionerList = () => {
    if (petitions && !_.isEmpty(petitions)) {
      setLoader(true);
      getAllListData(
        `${COMMON_ENDPOINT.ReviewDocuments}/${Number(
          petitionId ? petitionId : editId,
        )}/${Number(petitions.petitionData?.beneficiary_id)}`,
      ).then((resp: any) => {
        const tempBeneficiaryArray = [];
        const tempPetitioneryArray = [];
        resp && resp?.length
          ? resp.filter(
              (val) =>
                val?.multipleDocumentsList && val?.multipleDocumentsList.length,
            )
          : [];
        resp?.map((val) => {
          if (val.module_name == 'Beneficiary') {
            tempBeneficiaryArray.push(val);
          } else {
            tempPetitioneryArray.push(val);
          }
        });
        setBeneficiaryDocuments([...tempBeneficiaryArray]);
        setPetitioneryDocuments([...tempPetitioneryArray]);
        setLoader(false);
      });
    }
  };
  const getRequiredDocumentList = () => {
    getAllListData(
      `${COMMON_ENDPOINT.Petitions}?skip=${String(
        params?.skip,
      )}&firmId=${String(params?.firmId)}&id=${String(
        params?.id,
      )}&leadId=${String(params?.leadId)}&name=${String(
        params?.name,
      )}&organizationId=${String(params?.organizationId)}&take=${String(
        params?.take,
      )}`,
    ).then(async (resp: any) => {
      setPetitionerId(resp?.data[0]?.petitioner_assigned);
      setPetitonerStatus(resp?.data[0].petitioner_associated);
      setRequestDocuments(resp);
      if (searchParams.get('leadUser') !== '' && userDetail.role_id === 4) {
        await getAllListData(
          `${COMMON_ENDPOINT.ReviewDocuments}/${Number(
            petitionId ? petitionId : editId,
          )}/${Number(
            searchParams.get('leadUser') !== ''
              ? resp.data[0].beneficiary_id
              : petitions.petitionData?.beneficiary_id,
          )}`,
        ).then((resp: any) => {
          const tempBeneficiaryArray = [];
          const tempPetitioneryArray = [];
          resp && resp?.length
            ? resp.filter(
                (val) =>
                  val?.multipleDocumentsList &&
                  val?.multipleDocumentsList.length,
              )
            : [];
          resp?.map((val) => {
            if (val.module_name == 'Beneficiary') {
              tempBeneficiaryArray.push(val);
            } else {
              tempPetitioneryArray.push(val);
            }
          });
          setBeneficiaryDocuments([...tempBeneficiaryArray]);
          setPetitioneryDocuments([...tempPetitioneryArray]);
          setLoader(false);
        });
      }
    });
  };

  const getSkilBukDocCount = (petitionId: any) => {
    getAllListData(
      `${COMMON_ENDPOINT.DocumentParse}/bulkdoc/count?id=${String(
        petitionId,
      )}&uploadFor=petition`,
    )
      .then((resp) => {
        setCountDetails((pre) => ({
          ...pre,
          pending: !_.isEmpty(resp)
            ? resp.pendingCount
              ? resp.pendingCount
              : 0
            : 0,
        }));
      })
      .catch((e) => {
        toast.error(e, alertOptions);
      });
  };

  const getLeadInfo = () => {
    if (petitions && petitions?.petitionData?.lead_id) {
      getAllListData(
        `${COMMON_ENDPOINT.LeadList}?id=${String(
          petitions && petitions?.petitionData?.lead_id,
        )}`,
      ).then((resp) => {
        if (
          userDetail.userRoleName === 'attorney' ||
          (petitions?.petitionData?.isAffilateCase &&
            petitions?.petitionData?.affilateRoleId === 3)
        ) {
          getSkilBukDocCount(
            petitionId
              ? petitionId
              : petitions && petitions?.petitionData?.lead_id,
          );
        }
        setLeadInfo(
          resp && resp.data && resp.data.length && resp.data[0]
            ? resp.data[0]
            : null,
        );
      });
    }
  };
  const copyDocumentList = () => {
    getAllListData(
      `${COMMON_ENDPOINT.CopyDocumentLead}/${String(
        petitionerId,
      )}?associated=${String(petitonerStatus)}&firmId=${String(
        debouncedParams.firmId,
      )}&visaId=${String(debouncedParams.visaId)}&documentType=${String(
        debouncedParams.documentType,
      )}&skip=&take=&uploadOnly=true`,
    ).then((resp: any) => {
      setCopyOrganizationDocument((pre) => ({
        ...pre,
        data: resp.data,
      }));
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const getList = useCallback(() => {
    getRequiredDocumentList();
    getLeadInfo();
    userDetail.role_id !== 4 && getPetitionerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  const editUploadDocuments = useCallback(
    async (formData: FormData) => {
      const fileUploadOpen = await instantState(setFileUploadOpen);
      try {
        setLoader(true);
        await updateData(
          '',
          formData,
          COMMON_ENDPOINT.uploadDocumentFileEdit(
            fileUploadOpen?.editDocId,
            fileUploadOpen.roleId,
          ),
        );
        getList();
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
          roleId: null,
        }));
      } catch (error) {
        setLoader(false);
      }
    },
    [getList],
  );
  const createUploadDocuments = useCallback(
    async (formData: FormData | any, dialogNotClosing?: any) => {
      try {
        const fileUploadOpen = await instantState(setFileUploadOpen);
        const tabValue = await instantState(setTabValue);
        setLoader(true);
        await addNewData(
          formData,
          COMMON_ENDPOINT.uploadDocumentFile(
            petitionId ? petitionId : editId,
            tabValue == '2'
              ? 2
              : tabValue == '1'
              ? fileUploadOpen.roleId
              : null,
            tabValue == '1'
              ? searchParams.get('leadUser') !== '' &&
                userDetail.role_id === 4 &&
                !_.isEmpty(requestDocuments)
                ? requestDocuments.data[0].beneficiary_id
                : petitions?.petitionData?.beneficiary_id
              : searchParams.get('leadUser') !== '' &&
                userDetail.role_id === 4 &&
                !_.isEmpty(requestDocuments)
              ? requestDocuments.data[0].organization_id
              : petitions?.petitionData?.organization_id,
          ),
        );
        getList();
        resetParseDocStateValues();
        if (
          dialogNotClosing &&
          userDetail.userRoleName === UserRoleType.Attorney
        ) {
          setLoader(false);
          setTempI_129(true);
        }
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
          roleId: null,
        }));
        setPreview(false);
      } catch (error) {
        setLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editId,
      getList,
      location.state?.data?.leadUserId,
      location.state?.data?.personId,
      userDetail.userRoleName,
    ],
  );

  const getPassportPaesingDoc = (entity) => {
    setPassportValues({
      countryName: {
        confidence:
          typeof entity.countryName === 'string' ||
          entity.countryName instanceof String
            ? '90'
            : entity.countryName && entity.countryName.confidence
            ? entity.countryName.confidence
            : '90',
        value:
          typeof entity.countryName === 'string' ||
          entity.countryName instanceof String
            ? entity.countryName
            : entity.countryName && entity.countryName.value
            ? entity.countryName.value
            : '',
      },
      dateOfBirth: {
        confidence:
          typeof entity.dateOfBirth === 'string' ||
          entity.dateOfBirth instanceof String
            ? '90'
            : entity.dateOfBirth && entity.dateOfBirth.confidence
            ? entity.dateOfBirth.confidence
            : '90',
        value:
          typeof entity.dateOfBirth === 'string' ||
          entity.dateOfBirth instanceof String
            ? changeDobFormate(entity.dateOfBirth)
            : entity.dateOfBirth && entity.dateOfBirth.value
            ? changeDobFormate(entity.dateOfBirth.value)
            : '',
      },
      expiryDate: {
        confidence:
          typeof entity.expiryDate === 'string' ||
          entity.expiryDate instanceof String
            ? '90'
            : entity.expiryDate && entity.expiryDate.confidence
            ? entity.expiryDate.confidence
            : '90',
        value:
          typeof entity.expiryDate === 'string' ||
          entity.expiryDate instanceof String
            ? formateDate(entity.expiryDate)
            : entity.expiryDate && entity.expiryDate.value
            ? formateDate(entity.expiryDate.value)
            : '',
      },
      firstName: {
        confidence:
          typeof entity.firstName === 'string' ||
          entity.firstName instanceof String
            ? '90'
            : entity.firstName && entity.firstName.confidence
            ? entity.firstName.confidence
            : '90',
        value:
          typeof entity.firstName === 'string' ||
          entity.firstName instanceof String
            ? entity.firstName
            : entity.firstName && entity.firstName.value
            ? entity.firstName.value
            : '',
      },
      gender: {
        confidence:
          typeof entity.gender === 'string' || entity.gender instanceof String
            ? '90'
            : entity.gender && entity.gender.confidence
            ? entity.gender.confidence
            : '90',
        value:
          typeof entity.gender === 'string' || entity.gender instanceof String
            ? entity.gender
            : entity.gender && entity.gender.value
            ? entity.gender.value
            : '',
      },
      lastName: {
        confidence:
          typeof entity.lastName === 'string' ||
          entity.lastName instanceof String
            ? '90'
            : entity.lastName && entity.lastName.confidence
            ? entity.lastName.confidence
            : '90',
        value:
          typeof entity.lastName === 'string' ||
          entity.lastName instanceof String
            ? entity.lastName
            : entity.lastName && entity.lastName.value
            ? entity.lastName.value
            : '',
      },
      middleName: { confidence: '', value: '' },
      nationality: {
        confidence:
          typeof entity.userNationality === 'string' ||
          entity.userNationality instanceof String
            ? '90'
            : entity.userNationality && entity.userNationality.confidence
            ? entity.userNationality.confidence
            : '90',
        value:
          typeof entity.userNationality === 'string' ||
          entity.userNationality instanceof String
            ? entity.userNationality
            : entity.userNationality && entity.userNationality.value
            ? entity.userNationality.value
            : '',
      },
      passportNumber: {
        confidence:
          typeof entity.passportNumber === 'string' ||
          entity.passportNumber instanceof String
            ? '90'
            : entity.passportNumber && entity.passportNumber.confidence
            ? entity.passportNumber.confidence
            : '90',
        value:
          typeof entity.passportNumber === 'string' ||
          entity.passportNumber instanceof String
            ? entity.passportNumber
            : entity.passportNumber && entity.passportNumber.value
            ? entity.passportNumber.value
            : '',
      },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getParsingResume = (entity) => {
    setResumeInfo((pre) => ({
      ...pre,
      [284]: getWorkingValues(entity),
      [474]: educationValues(entity),
    }));
  };
  const educationValues = (edu) => {
    let education = [];
    if (
      edu &&
      edu['EducationOrganizationAttendance'] &&
      edu.EducationOrganizationAttendance.length
    ) {
      education = edu.EducationOrganizationAttendance.map(
        (val) =>
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${val.MajorProgramName} ${val.School ? 'in ' : ''}${val.School}`,
      );
    }
    return education && education.length ? education : [''];
  };
  const getWorkingValues = (working) => {
    let work = [];
    if (
      working &&
      working['PositionHistory'] &&
      working.PositionHistory.length
    ) {
      work = working.PositionHistory.map(
        (val, index) =>
          `${index !== 0 ? (val ? '\n' : '') : ''}${
            val.Role ? String(val.Role) + ' in ' : ''
          }${String(val.Company)}${val.Company ? ',' : ''}${String(
            val.StartDate,
          )}${val.EndDate ? '-' : ''}${String(val.EndDate)}`,
      );
    }
    return work.toString();
  };
  const bodyFormData = useCallback(
    async (docData: any, preViewCheck?: boolean) => {
      let docformdata: any;

      if (!preViewCheck) {
        docformdata = new FormData();
        docformdata.append('file', copyFile);
        docformdata.append('documentData', JSON.stringify(docData));
        docformdata.append(
          'documentSelectedId',
          fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
        );
        docformdata.append('documentFor', 'petition');
        docformdata.append('submitted', preview ? 'true' : 'false');
      } else {
        docformdata = {
          documentData: JSON.stringify(docData),
          documentFor: 'petition',
          documentId: fileUploadOpen?.editDocId,
          documentSelectedId: fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
          submitted: preview,
        };
      }
      return await docformdata;
    },
    [
      fileUploadOpen.documentInfo?.currentDocumentId,
      fileUploadOpen?.editDocId,
      preview,
      copyFile,
    ],
  );
  const getPassportDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const passportValues: any = await instantState(setPassportValues);
      const docData = {
        ...passportValues,
        dateOfBirth: {
          confidence: passportValues.dateOfBirth.confidence,
          value: changeDobFormate(passportValues.dateOfBirth.value),
        },
        gender: {
          confidence: passportValues.gender.confidence,
          value: getGenderValues(passportValues.gender.value),
        },
      };
      return await bodyFormData(docData, previewCheck);
    },
    [bodyFormData],
  );
  const getResumeDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const arr = [];
      const resumeInfo: any = await instantState(setResumeInfo);
      arr.push({
        answer: JSON.stringify(resumeInfo[474].filter((e) => e)),
        id: 474,
      });
      arr.push({ answer: resumeInfo[284], id: 284 });
      return await bodyFormData(arr, previewCheck);
    },
    [bodyFormData],
  );
  const getLcaDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const finalArr = [];
      const parsingData: any = await instantState(setParsingData);
      parsingData.map((val: any) => {
        if (val.questionId) {
          if (val.response_type === 5 || val.response_type === 6) {
            val.answer =
              val.multiple_pdf_field && val.multiple_pdf_field.length
                ? JSON.stringify(val.multiple_pdf_field)
                : JSON.stringify([]);
          }
          finalArr.push(val);
        }
      });
      return await bodyFormData(finalArr, previewCheck);
    },
    [bodyFormData],
  );
  const uploadCorrect = useCallback(async () => {
    if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
      const formdata = await getPassportDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
      const formdata = await getResumeDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    } else if (
      fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
      fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
      fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
    ) {
      const formdata = await getLcaDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    }
  }, [
    createUploadDocuments,
    fileUploadOpen?.documentInfo?.parsing_type,
    getLcaDefaultFormData,
    getPassportDefaultFormData,
    getResumeDefaultFormData,
  ]);

  const getI_129DefaultFormData = useCallback(
    (formExtractionId, file) => {
      const docformdata = new FormData();
      docformdata.append('file', file);
      docformdata.append(
        'documentSelectedId',
        fileUploadOpen?.documentInfo?.currentDocumentId,
      );
      docformdata.append('documentData', JSON.stringify([]));
      docformdata.append('documentFor', 'petition');
      docformdata.append('formExtractionId', formExtractionId);
      return docformdata;
    },
    [fileUploadOpen?.documentInfo?.currentDocumentId],
  );
  const i_129PrsingDefaultDocValues = useCallback(
    (formExtractionId, file) => {
      const i129Values = getI_129DefaultFormData(formExtractionId, file);
      createUploadDocuments(i129Values, 'dialogNotClose');
    },
    [createUploadDocuments, getI_129DefaultFormData],
  );

  const getReferenceValues = useCallback(
    (parseId: any) => {
      return {
        beneficieryName:
          tabValue == '1'
            ? petitions?.petitionData?.benificiery_name
            : petitions?.petitionData?.organization,
        formExtractionId: parseId.formExtractionId
          ? parseId.formExtractionId
          : '',
        leadUserId: location.state?.data?.leadUserId,
        // ||
        // (petitionId ? petitionId : editId),
        petitionId:
          location.state?.data?.leadUserId ||
          (petitionId ? petitionId : editId),
      };
    },
    [
      editId,
      location.state?.data?.leadUserId,
      petitionId,
      petitions?.petitionData?.benificiery_name,
      petitions?.petitionData?.organization,
      tabValue,
    ],
  );
  const refrencekeySaveI_129ParsingDefaultDoc = useCallback(
    async (result: any, file: any) => {
      try {
        const reference = getReferenceValues(result);
        const orgId =
          userDetail.userRoleName === UserRoleType.Beneficiary ||
          userDetail.userRoleName === UserRoleType.Petitioner
            ? leadInfo && leadInfo['attorney_assigned']
              ? leadInfo.attorney_assigned
              : ''
            : location.state && location.state?.user === 'petitioner'
            ? location.state?.data?.personId
            : '';
        await addNewData(
          reference,
          COMMON_ENDPOINT.uploadDocumentI_129RefrenceIdSave(
            orgId,
            fileUploadOpen.roleId,
            leadInfo && leadInfo.attorney_contact_id
              ? leadInfo.attorney_contact_id
              : '',
          ),
        );
        i_129PrsingDefaultDocValues(
          result && result.formExtractionId ? result.formExtractionId : '',
          file,
        ); // Addition Udation Default DOc values
      } catch (error) {
        i_129PrsingDefaultDocValues(
          result && result.formExtractionId ? result.formExtractionId : '',
          file,
        ); // Addition Udation Default DOc values
      }
    },
    [
      fileUploadOpen.roleId,
      getReferenceValues,
      i_129PrsingDefaultDocValues,
      leadInfo,
      location.state,
      userDetail.userRoleName,
    ],
  );
  const getLcaActualQuestionAndAnswer = useCallback(
    async (array: Array<any>) => {
      try {
        const parsingResponse = await addNewData(
          { list: array, type: fileUploadOpen?.documentInfo?.parsing_type },
          COMMON_ENDPOINT.uploadDocumentGetParsingValue,
        );
        const parsingVal =
          parsingResponse && parsingResponse.data ? parsingResponse.data : [];
        setParsingData(parsingVal);
        if (
          parsingVal.length === 0 ||
          !parsingVal.some((val) => val.questionId)
        ) {
          uploadCorrect();
          return;
        }
        if (
          userDetail?.userRoleName === UserRoleType.Beneficiary ||
          userDetail?.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        setPreview(true);
      } catch (error) {
        if (
          userDetail?.userRoleName === UserRoleType.Beneficiary ||
          userDetail?.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        setLoader(false);
        toast.error('values not parsed properly', alertOptions);
      }
    },
    [
      fileUploadOpen?.documentInfo?.parsing_type,
      uploadCorrect,
      userDetail?.userRoleName,
    ],
  );
  const mappingValues = useCallback(
    (arr: Array<any>) => {
      const lcaParsingValues = arr.map((val) => {
        const finalArr = [];
        if (val[1]) {
          const obj: object = val[1];
          for (const key in obj) {
            if (obj[key]) {
              const originalObj = {
                answer: '',
                keyValue: '',
              };
              const element = obj[key];
              originalObj.keyValue = key;
              originalObj.answer = element;
              if (element) {
                finalArr.push(originalObj);
              }
            }
          }
        }
        val[1] = finalArr;
        return val[1] && val[1].length > 0 ? val[1] : [];
      });
      const newArry = [].concat(...lcaParsingValues);
      if (newArry && newArry.length) {
        getLcaActualQuestionAndAnswer(newArry);
      } else {
        uploadCorrect();
      }
    },
    [getLcaActualQuestionAndAnswer, uploadCorrect],
  );

  const parsingDocuments = useCallback(
    async (file: any) => {
      resetParseDocStateValues();
      let uploadTypeCheck = '';
      if (fileUploadOpen?.documentInfo?.parsing_type) {
        uploadTypeCheck =
          fileUploadOpen?.documentInfo?.parsing_type === 'i-129'
            ? 'i129'
            : fileUploadOpen?.documentInfo?.parsing_type;
      }
      setLoader(true);
      try {
        const uploadType = await instantState(setUploadType);
        const docformdata = new FormData();
        docformdata.append('file', file);
        docformdata.append('uploadType', uploadTypeCheck);
        docformdata.append('uploadCheck', String(uploadType));
        docformdata.append('baseUrl', BASE_URL);
        docformdata.append(
          'beneficieryName',
          tabValue == '1'
            ? petitions?.petitionData?.benificiery_name
            : petitions?.petitionData?.organization,
        );
        docformdata.append(
          'documentId',
          fileUploadOpen?.documentInfo?.currentDocumentId,
        );
        docformdata.append(
          'petitionId',
          location.state?.data?.leadUserId ||
            (petitionId ? petitionId : editId),
        );
        const documentParsingResponse = await addNewData(
          docformdata,
          COMMON_ENDPOINT.uploadDocumentParsing(
            location.state?.data?.personId,
            fileUploadOpen.roleId,
            uploadTypeCheck,
          ),
        );
        const res: any = documentParsingResponse.responce;
        setLoader(false);
        setUploadType(false);
        if (res === 'Unauthorized') {
          const recallTime = await instantState(setRecallTime);
          if (recallTime < 4) {
            setUploadType(true);
            setRecallTime(Number(recallTime) + 1);
            parsingDocuments(file);
          } else {
            toast.error(
              'Could not update. Please try again after sometime',
              alertOptions,
            );
          }
          return;
        }
        setRecallTime(0);

        if (
          res &&
          res['entityResults'] &&
          res.entityResults['docClassification'] &&
          res.entityResults.docClassification === 'Unknown'
        ) {
          if (fileUploadOpen?.documentInfo?.parsing_type === 'i-129') {
            toast.error('Upload a valid I-129 PDF', alertOptions);
            return;
          }
          toast.error(
            fileUploadOpen?.documentInfo?.parsing_type === 'passport'
              ? 'Upload a valid passport'
              : fileUploadOpen?.documentInfo?.parsing_type === 'lca'
              ? 'Upload a valid LCA document'
              : 'Upload a valid document',
            alertOptions,
          );
          return;
        } else if (
          (typeof res === 'string' || res instanceof String) &&
          userDetail.userRoleName === UserRoleType.Attorney
        ) {
          const errorRes: any = res;
          toast.error(
            errorRes ? errorRes : 'Something went wrong',
            alertOptions,
          );
          return;
        }

        if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
          if (
            res.results &&
            (res.results['firstName'] ||
              res.results['lastName'] ||
              res.results['gender'] ||
              res.results['dateOfBirth'] ||
              res.results['userNationality'] ||
              res.results['countryName'] ||
              res.results['passportNumber'] ||
              res.results['expiryDate']) &&
            res.results.firstName
          ) {
            getPassportPaesingDoc(res.results);
            if (
              userDetail.userRoleName === UserRoleType.Beneficiary ||
              userDetail.userRoleName === UserRoleType.Petitioner
            ) {
              uploadCorrect();
              return;
            }
          } else {
            uploadCorrect();
            return;
          }
        } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
          if (
            res.entityResults &&
            (res.entityResults['EducationOrganizationAttendance'] ||
              res.entityResults['PositionHistory'])
          ) {
            getParsingResume(res.entityResults);
            if (
              userDetail.userRoleName === UserRoleType.Beneficiary ||
              userDetail.userRoleName === UserRoleType.Petitioner
            ) {
              uploadCorrect();
              return;
            }
          } else {
            uploadCorrect();
            return;
          }
        } else if (
          fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
          fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
          fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
        ) {
          const lcaArr =
            res.entityResults && res?.entityResults?.data
              ? Object.entries(res.entityResults.data)
              : [];
          if (!lcaArr || lcaArr.length === 0) {
            uploadCorrect();
            return;
          }
          mappingValues(lcaArr);
          return;
        } else if (fileUploadOpen?.documentInfo?.parsing_type === 'i-129') {
          setLoader(true);
          refrencekeySaveI_129ParsingDefaultDoc(res, file);
          return;
        }
        setPreview(true);
        setFileUploadOpen((pre) => ({
          ...pre,
          open: false,
        }));
      } catch (error) {
        setLoader(false);
        if (
          userDetail.userRoleName === UserRoleType.Beneficiary ||
          userDetail.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        // toast.error(error ? String(error) : '', alertOptions);
      }
    },
    [
      editId,
      fileUploadOpen?.documentInfo?.currentDocumentId,
      fileUploadOpen?.documentInfo?.parsing_type,
      fileUploadOpen.roleId,
      getParsingResume,
      location.state?.data?.leadUserId,
      location.state?.data?.personId,
      mappingValues,
      petitionId,
      petitions?.petitionData?.benificiery_name,
      petitions?.petitionData?.organization,
      refrencekeySaveI_129ParsingDefaultDoc,
      tabValue,
      uploadCorrect,
      userDetail.userRoleName,
    ],
  );
  const uploadDoc = useCallback(
    (file) => {
      if (file) {
        const objectURL = URL.createObjectURL(file);
        setFileSrc(objectURL);
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'documentSelectedId',
          fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
        );
        formData.append('documentFor', 'petition');
        if (
          parsingTypeArr.includes(fileUploadOpen?.documentInfo?.parsing_type)
        ) {
          parsingDocuments(file);
          return;
        }
        setFileUploadOpen((pre) => ({
          ...pre,
          open: false,
        }));
        if (fileUploadOpen?.editDocId) {
          editUploadDocuments(formData);
        } else {
          createUploadDocuments(formData);
        }
      }
    },
    [
      createUploadDocuments,
      editUploadDocuments,
      fileUploadOpen.documentInfo?.currentDocumentId,
      fileUploadOpen.documentInfo?.parsing_type,
      fileUploadOpen?.editDocId,
      parsingDocuments,
    ],
  );
  const deleteUploadDoc = (doc: any) => {
    setLoader(true);

    deleteData(doc.documentId, COMMON_ENDPOINT.ReviewDocuments)
      .then((resp: any) => {
        toast.success('File deleted.', alertOptions);
        setConfirmOpen({
          doc: {},
          index: null,
          show: false,
        });
        getList();
      })
      .catch((e) => {
        toast.error(e ? e : 'Oops!!! Something went wrong', alertOptions);
        setLoader(false);
      });
  };
  const closeUploadDialog = useCallback(
    (action: any) => {
      if (action) {
        setCopyFile(action);
        uploadDoc(action);
      } else {
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
        }));
      }
    },
    [uploadDoc],
  );
  const resumeOnConfirm = (type: string, info: any) => {
    if (type === 'reUpload') {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: true,
      }));
      setPreview(false);
      resetParseDocStateValues();
      return;
    }
    if (type === 'submit') {
      if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
        setPassportValues({ ...info });
      } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
        setResumeInfo({ ...info });
      } else if (
        fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
        fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
        fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
      ) {
        setParsingData([...info]);
      }
      uploadCorrect();
      return;
    }
    setFileUploadOpen((pre) => ({
      ...pre,
      open: false,
    }));
    resetParseDocStateValues();
    setPreview(false);
  };
  const resetParseDocStateValues = () => {
    setPassportValues({ ...passportDefaultValues });
    setResumeInfo({ ...resumeDefaultValues });
    setParsingData([]);
  };
  const checkToken = useCallback(async () => {
    const uploadCheck = await instantState(setUploadCheck);
    const doc = {
      documentFor: 'petition',
      leadId: petitionId ? petitionId : editId,
      uploadCheck: uploadCheck,
      uploadForId: '',
      uploadedFor: '',
    };
    setLoader(true);
    await addNewData(doc, `${COMMON_ENDPOINT.BulkDocumentUpload}/review`)
      .then((res) => {
        if (res === 'Unauthorized') {
          setUploadCheck(true);
          checkToken();
          return;
        }
        setUploadCheck(false);
        // setIsDocumentReview({ reviewDoc: true, token: res.data });
        setLoader(false);
        const TokenStatus: any = Storage.getTokens();
        const token: string =
          TokenStatus && TokenStatus.token ? TokenStatus.token : '';
        navigate(
          `${AttorneyPrivateRoutes.PETITIONS.PETITIONREVIEWLIST}/${String(
            petitionId ? petitionId : editId,
          )}`,
          {
            state: {
              review: {
                reviewDoc: true,
                token: `${String(res.data)}&token=${token}&envName=${String(
                  process.env.REACT_APP_ENV,
                )}`,
              },
            },
          },
        );
      })
      .catch((err) => {
        setUploadCheck(false);
        setLoader(false);
        // toast.error(err, alertOptions);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openRequiredAddDocument = (data) => {
    navigate(
      `${String(AttorneyPrivateRoutes.PETITIONS.ADDREQUESTDOCUMENT)}/${String(
        petitionId ? petitionId : editId,
      )}${
        searchParams.get('orgId') ? '?orgId=' + searchParams.get('orgId') : ''
      }${
        searchParams.get('invited')
          ? '&invited=true&isPetition=true'
          : searchParams.get('associated')
          ? '&associated=true&isPetition=true'
          : ''
      }`,
    );
  };
  const openRequiredDocument = (data) => {
    setRequiredDocumentSection((pre) => ({
      ...pre,
      open: true,
      scheduleData: data,
    }));
  };
  const visaTypeListApi = () => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        setVisaTypes(resp.data);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    if (!_.isEmpty(petitions)) {
      // console.log(petitions);
      getList();
    }
    visaTypeListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [petitions]);

  return (
    <>
      {!_.isEmpty(petitions) && petitions?.petitionData ? (
        <Typography
          component={'div'}
          className="black-white-text"
          sx={{
            fontFamily: 'Arial-Bold !important',
            fontSize: '1.25em !important',
          }}>
          {petitions?.petitionData?.visa_type} {'for'}{' '}
          {petitions?.petitionData?.benificiery_name}
        </Typography>
      ) : (
        ''
      )}
      <Grid container>
        <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
          <Box
            sx={{ marginTop: '1.5rem', width: '100%' }}
            className="uploadTab">
            {loader && <div className="liberty-loader" />}
            <>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example">
                    <Tab label="Beneficiary" value="1" />
                    {userDetail.userRoleName !== UserRoleType.Beneficiary && (
                      <Tab label="Petitioner" value="2" />
                    )}
                    <Tab label="Dependent" value="4" />
                    {userDetail.userRoleName === UserRoleType.Attorney &&
                      !petitions?.petitionData?.isAffilateCase && (
                        <Tab
                          label={
                            <div>
                              <a
                                style={{
                                  fontFamily: 'Arial-Bold',
                                  marginRight: '12px',
                                }}>
                                + / -
                              </a>
                              <a
                                style={{
                                  fontFamily: 'Arial-Bold',
                                }}>
                                {' '}
                                Documents{' '}
                              </a>{' '}
                            </div>
                          }
                          value="3"
                        />
                      )}
                  </TabList>
                </Box>
                <Typography
                  component={'div'}
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '0px 8px 8px 8px',
                    minHeight: '130px',
                  }}>
                  {tabValue === '1' ? (
                    <TabPanel value="1">
                      <ul className="upload-ul">
                        {beneficiaryDocuments.length > 0 ? (
                          beneficiaryDocuments.map((document, index) => (
                            <UploadDocumentList
                              document={document}
                              leadInfo={leadInfo}
                              index={index}
                              setFileUploadOpen={setFileUploadOpen}
                              setLoader={setLoader}
                              key={index}
                              userDetail={userDetail}
                              setConfirmOpen={setConfirmOpen}
                              setIsUploadDocs={setIsUploadDocs}
                              isUploadDocs={isUploadDocs}
                              parsedValuesView={parsedValuesView}
                              petitions={petitions}
                            />
                          ))
                        ) : (
                          <Typography className="d-flex content-center w-100 mt-2">
                            No Record(s) Found.
                          </Typography>
                        )}
                      </ul>
                    </TabPanel>
                  ) : tabValue === '2' &&
                    userDetail.userRoleName !== UserRoleType.Beneficiary ? (
                    <TabPanel value="2">
                      <ul className="upload-ul">
                        {petitions.petitionData?.petitioner_assigned ? (
                          petitioneryDocuments.length > 0 ? (
                            petitioneryDocuments.map((document, index) => (
                              <UploadDocumentList
                                document={document}
                                leadInfo={leadInfo}
                                index={index}
                                setFileUploadOpen={setFileUploadOpen}
                                setLoader={setLoader}
                                key={index}
                                userDetail={userDetail}
                                setConfirmOpen={setConfirmOpen}
                                setIsUploadDocs={setIsUploadDocs}
                                isUploadDocs={isUploadDocs}
                                parsedValuesView={parsedValuesView}
                                petitions={petitions}
                              />
                            ))
                          ) : (
                            <Typography className="d-flex content-center w-100 mt-2">
                              No Record(s) Found.
                            </Typography>
                          )
                        ) : (
                          <Typography
                            className="d-flex content-center w-100 mt-2"
                            sx={{ color: '#ff0000' }}>
                            You are not allow to upload petitioner document.
                          </Typography>
                        )}
                      </ul>
                    </TabPanel>
                  ) : tabValue === '4' ? (
                    <TabPanel value="4">
                      <Typography
                        component={'div'}
                        sx={{ padding: '2rem 1rem' }}>
                        <Depentent
                          DependentuploadDocument={'dependentUpload'}
                          leadUserId={searchParams.get('leadUser')}
                        />
                      </Typography>
                    </TabPanel>
                  ) : (
                    userDetail.userRoleName === UserRoleType.Attorney &&
                    !petitions?.petitionData?.isAffilateCase && (
                      <TabPanel value="3">
                        <Typography
                          component={'div'}
                          sx={{ padding: '2rem 1rem' }}>
                          <table
                            className="lead-table"
                            style={{
                              backgroundColor: '#fff',
                              minHeight: '106px',
                            }}>
                            <UploadPetitionsDocumentTableHeadting />
                            <tbody>
                              {!_.isEmpty(requestDocuments?.data) &&
                              requestDocuments.data.length > 0 ? (
                                requestDocuments.data.map((data, index) => (
                                  <UploadPetitionsDocumentTableDisplay
                                    data={data}
                                    key={index}
                                    openRequiredDocument={openRequiredDocument}
                                    openRequiredAddDocument={
                                      openRequiredAddDocument
                                    }
                                  />
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={3}>
                                    <Typography className="d-flex d-flex-sb w-100">
                                      <Typography
                                        component={'div'}
                                        sx={{ paddingLeft: '.5rem' }}
                                        className="d-flex-1">
                                        <Typography
                                          className="ctitle"
                                          sx={{
                                            opacity: '0.9',
                                          }}>
                                          No Record(s) Found.
                                        </Typography>
                                      </Typography>
                                    </Typography>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {/* <Typography component={'div'} className="custom-page">
                            <Pagination
                              total={
                                !_.isEmpty(requestDocuments?.data)
                                  ? Number(requestDocuments?.total)
                                  : 0
                              }
                              skip={params.skip}
                              take={params.take}
                              setParams={setParams}
                            />
                          </Typography> */}
                        </Typography>
                      </TabPanel>
                    )
                  )}
                </Typography>
              </TabContext>
              {fileUploadOpen.open && (
                <FileUploadDialog
                  open={fileUploadOpen.open}
                  onConfirm={closeUploadDialog}
                  allowedFileType={fileTypeCheckSkillParsing}
                  errorMessage={
                    'You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only'
                  }
                  info={
                    '(You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only.You can upload upto a maximum of 90MB per upload)'
                  }
                  isParsingDoc={parsingTypeArr.includes(
                    fileUploadOpen.documentInfo?.parsing_type,
                  )}
                  setCopyFile={setCopyFile}
                  loader={loader}
                />
              )}
              {preview && (
                <CustomModalView
                  open={true}
                  fullWidth={true}
                  maxWidth={'xl'}
                  scroll={'paper'}
                  autoCloseDisable={true}
                  close={() => setPreview(false)}>
                  {fileUploadOpen?.documentInfo?.parsing_type === 'resume' && (
                    <ResumeParsing
                      fileUrl={fileSrc}
                      resumeInfo={resumeInfo}
                      onConfirm={resumeOnConfirm}
                      setResumeInfo={setResumeInfo}
                      fileType={
                        copyFile &&
                        copyFile.type &&
                        copyFile.type.split('/')[1] === 'pdf'
                          ? 'pdf'
                          : copyFile?.type
                      }
                    />
                  )}

                  {fileUploadOpen?.documentInfo?.parsing_type ===
                    'passport' && (
                    <PassportParsing
                      fileUrl={fileSrc}
                      passportInfo={passportValues}
                      onConfirm={resumeOnConfirm}
                      setPassportInfo={setPassportValues}
                      fileType={
                        copyFile &&
                        copyFile.type &&
                        copyFile.type.split('/')[1] === 'pdf'
                          ? 'pdf'
                          : copyFile?.type
                      }
                    />
                  )}

                  {(fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
                    fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
                    fileUploadOpen?.documentInfo?.parsing_type === 'i-140') && (
                    <PdfParsing
                      fileUrl={fileSrc}
                      parseInfo={parsingData}
                      onConfirm={resumeOnConfirm}
                      setParseInfo={setParsingData}
                      fileType={
                        copyFile &&
                        copyFile.type &&
                        copyFile.type.split('/')[1] === 'pdf'
                          ? 'pdf'
                          : copyFile?.type
                      }
                    />
                  )}
                </CustomModalView>
              )}
              {tempI_129 && (
                <CustomModalView open={true} close={() => setTempI_129(false)}>
                  <DialogContent>
                    <div className="tempI129">
                      <section>
                        <img className="logo" src={Success} alt="complete" />
                        <h5 className="card-title" style={{ margin: 'unset ' }}>
                          <span> File Uploaded </span>
                        </h5>
                      </section>
                      <p>
                        We are working on processing the I-129 form. This will
                        take few minutes to process the complete file and fetch
                        the details from I-129 which can be pushed into the
                        current petition. You can continue with your other
                        activities while the file is being processed in the
                        background. You will be notified once it is completed
                        and you can click on the notification to edit/modify the
                        data we retrieved from I-129.
                      </p>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      className="n-add-btn"
                      sx={{
                        borderRadius: '5px !important',
                        float: 'right',
                        fontFamily: 'Arial-Regular !important',
                        fontSize: '0.9em !important',
                        height: '35px !important',
                        margin: '15px',
                        width: '149px !important',
                      }}
                      onClick={() => setTempI_129(false)}>
                      {'Ok'}
                    </Button>
                  </DialogActions>
                </CustomModalView>
              )}
            </>
          </Box>

          <ConfirmDialog
            title="DELETE"
            floatingBtnShow={true}
            open={confirmOpen.show}
            onConfirm={(act) =>
              act === 'yes'
                ? deleteUploadDoc(confirmOpen.doc)
                : setConfirmOpen({
                    doc: {},
                    index: null,
                    show: false,
                  })
            }
            autoCloseDisable={true}>
            Are you sure do you want to delete?
          </ConfirmDialog>

          {copyDocumentSection.open && (
            <CustomModalView
              open={copyDocumentSection.open}
              fullWidth={true}
              maxWidth={'xl'}
              scroll={'paper'}
              autoCloseDisable={true}
              isFilters={true}
              visaTypes={visaTypes}
              title="Petitions List"
              filterOnChange={(e) => setSectionSearch(e)}
              filterVisaOnChange={(e) => setVisaSearch(e)}
              close={() =>
                setCopyDocumentSection((pre) => ({
                  ...pre,
                  open: false,
                }))
              }>
              <SectionList
                sectionType={'petitions'}
                searchValue={sectionSearch}
                visaSearch={visaSearch}
                onSelect={selectSections}
                petitionData={
                  searchParams.get('leadUser') !== '' &&
                  userDetail.role_id === 4 &&
                  !_.isEmpty(requestDocuments)
                    ? requestDocuments.data[0]
                    : petitions?.petitionData
                }
                setCopyDocumentSection={setCopyDocumentSection}
                getList={getList}
                visasNameShown={true}
              />
            </CustomModalView>
          )}
          {requiredDocumentSection.open && (
            <CustomModalView
              open={requiredDocumentSection.open}
              fullWidth={true}
              maxWidth={'xl'}
              scroll={'paper'}
              autoCloseDisable={true}
              isFilters={false}
              title="Documents"
              // filterOnChange={(e) => setSectionSearch(e)}
              close={closeDocumentSection}>
              <SectionDocumentsList
                requiredDocumentSection={requiredDocumentSection}
                editId={petitionId ? petitionId : editId}
                closeDocumentSection={closeDocumentSection}
              />
            </CustomModalView>
          )}
        </Grid>
        <Grid item md={2} sm={12}>
          <Typography component={'div'}>
            <Typography component={'div'} className="bulk-add-box">
              {/* <Typography component={'div'} className="add-inner mb-1">
              <Typography component={'div'} className="add-inner-box" />
              <Typography className="link-txt f-16 pt-5 pb-2 pl-2">
                Employer Support Letter
              </Typography>
              <Typography className=" bu-color f-16 pb-2 pl-2 l-y">
                The employer support letter is used to inform USCIS of the
                employers intent and description of the position, the location,
                pay and its term. It also discusses the beneficiarys background
                and how it meets the requirements of the job.
              </Typography>

              <Typography className=" built-option d-flex-a mb-2">
                <img
                  src={Ledit}
                  alt="im"
                  className="mr-1"
                  style={{ width: '20px !important' }}
                />
                Upload new template
              </Typography>
            </Typography> */}
              {[2, 3, 4].includes(userDetail.role_id) && (
                <Typography component={'div'} className="add-inner mb-2">
                  <Typography component={'div'} className="add-inner-box" />
                  <Typography
                    component={'div'}
                    className=" bu-color pt-5 pb-2 pl-2">
                    <Typography component={'div'} className="bu-color d-flex-a">
                      <img
                        src={Bupload}
                        alt="im"
                        className="mr-1"
                        style={{ width: '20px !important' }}
                      />
                      Bulk Upload
                    </Typography>
                  </Typography>
                  <Typography className=" bu-color f-16 pb-2 pl-2 l-y pr-2">
                    Simply upload any documents that might be relevant, and I’ll
                    see what information I can get, saving you even more time.
                  </Typography>
                </Typography>
              )}
              <FileBulkUploadDialog
                visaId={petitions?.petitionData?.visa_id}
                petitionId={petitionId ? petitionId : editId}
                leadId={''}
                roleId={tabValue === '1' ? 4 : 2}
                userName={
                  searchParams.get('leadUser') !== '' &&
                  userDetail.role_id === 4 &&
                  !_.isEmpty(requestDocuments)
                    ? requestDocuments.data[0].organization
                    : petitions?.petitionData?.organization
                }
                personId={
                  searchParams.get('leadUser') !== '' &&
                  userDetail.role_id === 4 &&
                  !_.isEmpty(requestDocuments)
                    ? requestDocuments.data[0].beneficiary_id
                    : petitions?.petitionData?.beneficiary_id
                }
              />
              {userDetail.userRoleName === UserRoleType.Attorney &&
                tabValue === '2' &&
                !petitions?.petitionData?.isAffilateCase &&
                petitions.petitionData?.petitioner_assigned && (
                  <Typography
                    component={'div'}
                    className="add-inner mt-2 mb-2 pointer"
                    onClick={() => setUploadDocumentdailog(true)}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className=" d-flex-a bu-color pl-2 pt-2 pb-1">
                      <img
                        src={Bupload}
                        alt="im"
                        className="mr-1"
                        style={{ width: '20px !important' }}
                      />
                      Copy Document
                    </Typography>
                  </Typography>
                )}

              {userDetail.userRoleName === UserRoleType.Attorney &&
                (tabValue === '2' || tabValue === '1') &&
                !petitions?.petitionData?.isAffilateCase && (
                  <DocumentBulkDownload
                    documentList={
                      tabValue === '1'
                        ? beneficiaryDocuments &&
                          Array.isArray(beneficiaryDocuments) &&
                          beneficiaryDocuments.length
                          ? beneficiaryDocuments.filter(
                              (e) =>
                                e.multipleDocumentsList &&
                                e.multipleDocumentsList.length,
                            )
                          : []
                        : petitioneryDocuments &&
                          Array.isArray(petitioneryDocuments) &&
                          petitioneryDocuments.length
                        ? petitioneryDocuments.filter(
                            (e) =>
                              e.multipleDocumentsList &&
                              e.multipleDocumentsList.length,
                          )
                        : []
                    }
                    downloadFor={'petition'}
                    userName={
                      tabValue === '1'
                        ? petitions?.petitionData?.benificiery_name
                        : petitions?.petitionData?.organization
                    }
                  />
                )}
              {userDetail.userRoleName === UserRoleType.Attorney && (
                <Typography component={'div'} className="add-inner mt-2 mb-2">
                  <Typography component={'div'} className="add-inner-box" />
                  <Typography
                    className=" d-flex-a bu-color pl-2 pt-2 pb-1"
                    onClick={checkToken}
                    sx={{ cursor: 'pointer' }}>
                    <img
                      src={Bupload}
                      alt="im"
                      className="mr-1"
                      style={{ width: '20px !important' }}
                    />
                    Review Document
                    {countDetails.pending !== 0 && (
                      <Badge
                        badgeContent={Number(countDetails.pending)}
                        color="primary"
                        sx={{
                          maxWidth: 'max-content',
                          position: 'absolute',
                          right: '40px',
                          top: 15,
                        }}
                      />
                    )}
                  </Typography>
                </Typography>
              )}
            </Typography>
          </Typography>
        </Grid>
        {copyOrganizatioDocument.open && (
          <CustomModalView
            open={copyOrganizatioDocument.open}
            fullWidth={true}
            maxWidth={'xl'}
            scroll={'paper'}
            autoCloseDisable={true}
            visaTypes={visaTypes}
            filterVisaOnChange={(el) => {
              setCopyDocParams((pre) => ({
                ...pre,
                visaId: el,
              }));
            }}
            isFilters={true}
            title="Document List"
            filterOnChange={(searchvalue) => {
              setCopyDocParams((pre) => ({
                ...pre,
                documentType: searchvalue,
              }));
            }}
            close={() =>
              setCopyOrganizationDocument((pre) => ({
                ...pre,
                open: false,
              }))
            }>
            {loader && <div className="liberty-loader" />}
            <DialogContent>
              <table className="lead-table table-hover">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '50%' }}>
                      {'Document Type'}
                    </th>
                    <th scope="col">
                      Action
                      <Checkbox
                        size={'medium'}
                        checked={checkAll()}
                        onChange={(e) => selectAllDocument(e)}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {copyOrganizatioDocument &&
                  copyOrganizatioDocument.data.length ? (
                    copyOrganizatioDocument.data.map((data, index) => (
                      <tr key={index} style={{ cursor: 'pointer' }}>
                        <td>
                          <a
                            style={{
                              color: '#1093df',
                              fontFamily: ' Arial-Bold',
                              fontSize: '16px',
                            }}>
                            {data?.document_type}
                          </a>
                          {data.multipleDocumentsList.map((e, i) => {
                            return (
                              <Typography
                                key={i}
                                className="d-flex-a"
                                component={'div'}>
                                <Checkbox
                                  checked={e?.checked ? true : false}
                                  onChange={() =>
                                    removeOrAddInMultiDoc(
                                      copyOrganizatioDocument.data,
                                      index,
                                      i,
                                      !e?.checked as any,
                                    )
                                  }
                                />
                                <Typography>{e.original_name}</Typography>
                              </Typography>
                            );
                          })}
                        </td>
                        <td style={{ textTransform: 'capitalize' }}>
                          <Checkbox
                            size={'medium'}
                            checked={
                              data?.multipleDocumentsList &&
                              data?.multipleDocumentsList.every(
                                (multi) => multi.checked,
                              )
                            }
                            onChange={(e) =>
                              removeOrAddDoc(data, e.target.checked)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className={'text-center'}>
                      <th scope="row" colSpan={2}>
                        Master strategy was not created yet for this petitioner
                        . Please create master startegy
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </DialogContent>
            {copyOrganizatioDocument.data.filter((e) =>
              e.multipleDocumentsList.some((el) => el.checked),
            ).length ? (
              <DialogActions>
                <Typography
                  component={'div'}
                  className="d-flex-jae"
                  sx={{ padding: '0 1.5rem' }}>
                  <Button
                    className="n-add-btn"
                    sx={{
                      borderRadius: '5px !important',
                      float: 'right',
                      fontFamily: 'Arial-Regular !important',
                      fontSize: '0.9em !important',
                      height: '35px !important',
                      margin: '15px',
                      width: '149px !important',
                    }}
                    onClick={() => uploadSelectedDoc()}>
                    submit
                  </Button>
                </Typography>
              </DialogActions>
            ) : (
              ''
            )}
          </CustomModalView>
        )}
        <ConfirmDialog
          title="Document selection"
          selctionButton={true}
          open={uploadDocumentdailog}
          visibleClose={true}
          close={() => setUploadDocumentdailog(false)}
          onConfirm={(act) =>
            act === 'individual'
              ? openCopyDocument()
              : openOrganizationDocument()
          }
          autoCloseDisable={true}>
          Choose the option to copy the documents
        </ConfirmDialog>
      </Grid>
    </>
  );
};

export default UploadPetitionsDocument;
